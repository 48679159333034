const BioLines = [
    "I'm Dylan, originally from the peaceful town of Medford, NJ. My journey into",
    "the professional world began quite early when I took on the role of a rock",
    "climbing instructor in middle school. This early start not only developed my",
    "skills in the sport but also instilled in me a strong sense of leadership and",
    "responsibility.",
    "",
    "Music has been a constant companion throughout my life. My musical endeavors",
    "began with singing in a rock band during my middle and high school years,",
    "followed by participating in an A Cappella group in college. Currently, I",
    "continue to indulge my passion for music as a barbershop singer. Additionally,",
    "I've been a lifelong hobbyist guitarist, a skill that was an integral part of",
    "my band experiences.",
    "",
    "In the realm of my professional career, I've carved out a path as a software",
    "engineer. During my college years, I interned at Lowe's Home Insurance and",
    "Liberty Mutual Insurance Group, gaining invaluable experience. Post-graduation,",
    "I worked at GEICO in Chevy Chase, MD, and remotely for Liberty Mutual from",
    "Philadelphia. Presently, I am the lead UI developer for CaseSwift, a startup",
    "innovating in the Workman's compensation law field.",
    "",
    "Beyond my professional and musical pursuits, I have a profound love for rock",
    "climbing and acro yoga, reflecting my enthusiasm for adventure and physical",
    "fitness. I also find intellectual stimulation in solving Rubik's cubes, a hobby",
    "that challenges and entertains me.",
    "",
    "Family is a significant part of my life. I often visit Arizona to be with my",
    "parents. Growing up with German shepherds has instilled in me a deep",
    "appreciation for these loyal animals, which complements my caring nature.",
    "",
    "In short, my life is a diverse mix of passions and achievements, each aspect",
    "reflecting my dedication and zeal for life. Whether it's climbing, singing, or",
    "developing innovative software solutions, I bring creativity, intellect, and",
    "agility to everything I do."
];

export default BioLines;